module.exports = [{
      plugin: require('../plugins/gatsby-source-dynamodb-query/gatsby-browser.js'),
      options: {"plugins":[],"typeName":"inventory","region":"us-east-2","tableName":"AdjustedInventory","dealerId":["259134"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0674365f58c5f8c0868969284cb88e8e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
